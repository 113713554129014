<template>
	<div class="container">
		<div class="user-levels">
			<b-alert show>Users levels</b-alert>

			<div class="overflow-auto" v-if="total > 0">
				<b-pagination-nav
					@change="setPage"
					:link-gen="linkGen"
					:number-of-pages="totalPages"
					use-router
					limit="12"
					align="center"
				></b-pagination-nav>
			</div>

			<router-link
				:to="{ path: '/play-level/' + lvl.id + '/' + slug(lvl.name) }"
				v-for="lvl in levels"
				:key="lvl.id"
			>
				<span
					style="display: inline-block; font-size: 0.8em;"
					class="text-left"
				>
					<b-icon icon="eye" v-if="lvl.active"></b-icon>
					{{ lvl.showed_times }} 
					<b-icon icon="hand-thumbs-up"></b-icon>
					{{ calcScore(lvl) }}
					<br />

					<level-view
						:levelData="lvl.leveldata"
						:tileWidth="12"
						:levelName="lvl.name"
					></level-view>
				</span>
			</router-link>

			<div class="overflow-auto" v-if="total > 0">
				<b-pagination-nav
					@change="setPage"
					:link-gen="linkGen"
					:number-of-pages="totalPages"
					use-router
					limit="12"
					align="center"
				></b-pagination-nav>
			</div>
		</div>
	</div>
</template>

<style lang="scss"></style>

<script>
import LevelView from '../components/LevelView.vue';
import slugify from 'slugify';

export default {
	name: 'UserLevels',
	mounted: async function() {
		await this.setTotal();
		let page = this.$route.query.page || 1;
		await this.loadPage(page);
	},
	components: {
		LevelView
	},
	data: function() {
		return {
			total: 0,
			page: 1,
			levels: []
			// testLevel: '3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,3,3,4,0,0,0,0,0,0,0,0,0,0,1,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,0,6,0,0,0,0,0,6,0,39,29,12,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,12,28,40,0,0,6,0,0,0,6,0,0,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,0,0,0,0,0,6,0,0,0,39,29,12,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,12,28,40,0,0,0,0,6,0,0,0,0,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,0,4,0,4,0,4,0,4,0,0,0,4,3,3,4,3,0,0,6,0,0,0,6,0,0,39,29,12,3,3,4,3,4,0,4,0,4,0,4,0,4,0,0,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,0,0,4,0,4,0,4,0,4,0,4,3,4,3,3,12,28,40,0,6,0,0,0,0,0,6,0,3,4,3,3,4,0,0,0,4,0,4,0,4,0,4,0,3,4,3,3,4,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,4,3,5,0,0,0,0,0,0,0,0,0,0,4,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3'
		};
	},
	computed: {
		totalPages() {
			return Math.ceil(this.total / 15);
		}
	},
	methods: {
		calcScore(lvl) {
			if (lvl.score_count == 0) return 0;
			return Math.round(lvl.score_count / lvl.score);
		},
		slug(name) {
			return slugify(name);
		},
		setPage(page) {
			console.log('setPage', page);
			if (Number.isNaN(+page)) {
				page = 1;
			}
			this.page = +page;
			return this.loadPage(page);
		},
		linkGen(pageNum) {
			// console.log('linkGen', pageNum);
			// return pageNum === 1 ? '?' : `?page=${pageNum}`
			return {
				query: {
					page: pageNum
				}
			};
		},
		loadPage(page) {
			return fetch(`/api/v1/levels?page=${page}&size=15`)
				.then(data => data.json())
				.then(data => (this.levels = data.lvlData));
		},
		setTotal() {
			return fetch('/api/v1/levels/total')
				.then(data => data.json())
				.then(data => {
					this.total = data.total;
				});
		}
	}
};
</script>
